const Data = [
  {
    id: 0,
    name: "ChatNexus",
    projects: []
  },
  {
    id: 1,
    name: "Portfolio Website",
    projects: []
  },
  {
    id: 2,
    name: "GPA Calculator",
    projects: []
  },
  {
    id: 3,
    name: "Typing Test Website",
    projects: []
  },
  {
    id: 4,
    name: "Taskmate",
    projects: []
  },
  {
    id: 5,
    name: "Anime search React App",
    projects: []
  },
  {
    id: 6,
    name: "Ai Image Generator",
    projects: []
  },
  {
    id: 7,
    name: "Tic Tac Toe Game",
    projects: []
  },
  {
    id: 8,
    name: "Portfolio Website 2",
    projects: []
  }
];

export default Data;
