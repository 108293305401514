import { createSlice } from "@reduxjs/toolkit";
import windows from "./windows";

const desktopSlice = createSlice({
  name: "desktop",
  initialState: {
    activeWindows: [...windows.activeWindows],
    winstartToggled: false,
    pinnedApps: [...windows.pinnedApps],
    shortcutApps: [...windows.shortcutApps],
  },
  reducers: {
    newwindow: (state, action) => {
      const {
        height = "80vh",
        width = "80vw",
        x = 10,
        y = 10,
        title = "",
        minimized = false,
        maximized = false,
        Component = () => null,
      } = action.payload;

      const newId = state.activeWindows.length + 1;

      const newWindow = {
        id: newId,
        height,
        width,
        x,
        y,
        title,
        minimized,
        maximized,
        Component,
      };

      state.activeWindows.push(newWindow);
    },
    removewindow: (state, action) => {
      const filtered = state.activeWindows.filter(
        (win) => win.id !== action.payload
      );

      state.activeWindows = filtered;
    },

    minimizewindow: (state, action) => {
      const { id, minimized } = action.payload;

      const filtered = state.activeWindows.map((win) => {
        if (win.id === id) {
          win.minimized = minimized;
        }
        return win;
      });

      state.activeWindows = filtered;
    },

    maximizewindow: (state, action) => {
      const id = action.payload;

      const filtered = state.activeWindows.map((win) => {
        if (win.id === id) {
          win.maximized = !win.maximized;
        }
        return win;
      });

      state.activeWindows = filtered;
    },
    cancelMaximizewindow: (state, action) => {
      const id = action.payload;

      const filtered = state.activeWindows.map((win) => {
        if (win.id === id) {
          win.maximized = false;
        }
        return win;
      });

      state.activeWindows = filtered;
    },
    setstartToggled: (state, actions) => {
      state.winstartToggled = actions.payload;
    },

  },
});

export const {
  newwindow,
  removewindow,
  minimizewindow,
  maximizewindow,
  cancelMaximizewindow,
  setstartToggled,
} = desktopSlice.actions;

export default desktopSlice.reducer;
