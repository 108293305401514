import { icons,profiles } from "../assets";
import ProjectsApp from "../components/projects/projects";
import AboutApp from "../components/about/about";
import SkillsApp from "../components/skills/skills"
import { newwindow } from "./desktopSlice";

const WIN_FEATURES =
"toolbar=yes,scrollbars=yes,resizable=yes,top=150,left=50,width=800,height=700";

const Windows = {
  activeWindows: [],
  pinnedApps: [
    {
      name: "about me",
      icon: icons.apps.portfolio,
      width: 28,
      height: 28,
      onClick: (e, dispatch) => {
        dispatch(
          newwindow({
            title: "About me",
            height: "80vh",
            width: "80vw",
            x: 10,
            y: 10,
            minimized: false,
            maximized: false,
            Component: AboutApp,
          })
        );
      },
    },
    /*{
      name: "About me",
      icon: icons.apps.portfolio,
      width: 35,
      height: 35,
      onClick: (e, dispatch) => {
        dispatch(
          newActiveWindow({
            title: "portfolio",
            height: "80vh",
            width: "80vw",
            x: 10,
            y: 10,
            minimized: false,
            maximized: false,
            Component: About,
          })
        );
      },
    }*/
    {
      name: "projects",
      icon: icons.apps.projects,
      width: 35,
      height: 35,
      onClick: (e,dispatch) => {
        dispatch(
          newwindow({
            title: "portfolio",
            height: "80vh",
            width: "80vw",
            x: 10,
            y: 10,
            minimized: false,
            maximized: false,
            Component: ProjectsApp,
          })
        );
      },
    },
    {
      name: "skills",
      icon: icons.apps.skills,
      width: 35,
      height: 35,
      onClick: (e, dispatch) => {
        dispatch(
          newwindow({
            title: "skills",
            height: "80vh",
            width: "80vw",
            x: 10,
            y: 10,
            minimized: false,
            maximized: false,
            Component: SkillsApp,
          })
        );
      },
    },
  ],
  shortcutApps: [
    {
      name: "GitHub",
      icon: icons.apps.github,
      width: 35,
      height: 35,
      onClick: () => {
        window.open(
          "https://github.com/pydeep9026",
          "_blank",
          WIN_FEATURES
        );
      },
    },
    {
      name: "LinkedIn",
      icon: icons.apps.linkedin,
      width: 35,
      height: 35,
      onClick: () => {
        window.open(
          "https://www.linkedin.com/in/pradeep-singh-b57881207/",
          "_blank",
          WIN_FEATURES
        );
      },
    },
    {
      name: "Mail",
      icon: icons.apps.winMail,
      width: 35,
      height: 35,
      onClick: () => {
        const email = "pradeepsi2120032@gmail.com";
        const subject = "Hello";
        const body = "Hello pradeep ,\n\nI am contacting you regarding...";
        const mailToLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
        window.location.href = mailToLink;
      },
    },
    {
      name: "WhatsApp",
      icon: icons.apps.whatsapp,
      width: 28,
      height: 28,
      onClick: () => {
        const textMessage = encodeURIComponent(
          "Hello pradeep,...."
        );
        window.open(
          `https://wa.me/+919026003602?text=${textMessage}`,
          "_blank",
          WIN_FEATURES
        );
      },
    },
    {
      name: "about wallpaper",
        icon: profiles.aboutwallpaper,
        width: 35,
        height: 35,
        onClick: () => {
          window.open(
            "https://esawebb.org/images/weic2207b/",
            "_blank",
            WIN_FEATURES
          );
        },
      },
    {
    name: "youtube",
      icon: icons.apps.youtube,
      width: 35,
      height: 35,
      onClick: () => {
        window.open(
          "https://www.youtube.com/channel/UCpELs_OjqZamSyctq0Q8DSw",
          "_blank",
          WIN_FEATURES
        );
      },
    },
    {
      name: "location",
        icon: icons.apps.location,
        width: 35,
        height: 35,
        onClick: () => {
          window.open(
            "https://goo.gl/maps/Ftb8U9ejFro2saou7",
            "_blank",
            WIN_FEATURES
          );
        },
      },
    /*{
      name: "About me",
      icon: icons.apps.portfolio,
      width: 35,
      height: 35,
      onClick: (e, dispatch) => {
        dispatch(
          newActiveWindow({
            title: "portfolio",
            height: "80vh",
            width: "80vw",
            x: 10,
            y: 10,
            minimized: false,
            maximized: false,
            Component: About,
          })
        );
      },
    },*/
    {
      name: "About Me",
      icon: icons.apps.portfolio,
      width: 35,
      height: 35,
      onClick: (e, dispatch) => {
        dispatch(
          newwindow({
            title: "portfolio",
            height: "80vh",
            width: "80vw",
            x: 10,
            y: 10,
            minimized: false,
            maximized: false,
            Component: AboutApp,
          })
        );
      },
    },
    {
      name: "projects",
      icon: icons.apps.projects,
      width: 35,
      height: 35,
      onClick: (e, dispatch) => {
        dispatch(
          newwindow({
            title: "portfolio",
            height: "80vh",
            width: "80vw",
            x: 10,
            y: 10,
            minimized: false,
            maximized: false,
            Component: ProjectsApp,
          })
        );
      },
    },
    {
      name: "skills",
      icon: icons.apps.skills,
      width: 35,
      height: 35,
      onClick: (e, dispatch) => {
        dispatch(
          newwindow({
            title: "skills",
            height: "80vh",
            width: "80vw",
            x: 10,
            y: 10,
            minimized: false,
            maximized: false,
            Component: SkillsApp,
          })
        );
      },
    },
  ],
};

export default Windows;
